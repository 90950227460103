'use client'

import {
  CircularProgress,
  FormControl,
  Link,
  MenuItem,
  Select,
} from '@mui/material'
import { captureException } from '@sentry/nextjs'
import { useWallet } from '@solana/wallet-adapter-react'
import { GetServerSideProps, NextPage } from 'next'
import Image from 'next/image'
import { useSearchParams } from 'next/navigation'
import { FormEvent, useCallback, useState } from 'react'
import { Cart } from 'shopify-buy'
import { Connect } from '../../components/Connect'
import HTMLHead from '../../components/HTMLHead'
import { ExpandMoreIcon } from '../../components/SVGIcons/ExpandMore'
import { WhiteButton } from '../../components/WhiteButton'
import { useCheckoutCart } from '../../context/useCheckoutCart'
import { useInfoBox } from '../../context/useInfoBox'
import { DomainError, getErrorMessage } from '../../lib/errors'
import {
  addCustomAttributes,
  addDiscount,
  getCustomAttributes,
  removeCustomAttributes,
} from '../../lib/shopify/checkout'
import { getPreorderCollection } from '../../lib/shopify/collections'
import { COUNTRIES } from '../../lib/utils/countries'
import { targetBlank } from '../../utils/linkUtils'

import { withIronSessionSsr } from 'iron-session/next'
import { sessionOptions } from '../../lib/session'
import {
  EVENT_TYPE,
  useTwitterConversionEvent,
} from '../../providers/TwitterConversionContext'

interface PreorderProps {
  twitterId?: string
  referralExists?: boolean
}

const Preorder: NextPage<PreorderProps> = ({ twitterId, referralExists }) => {
  const [customerRole, setCustomerRole] = useState('')
  const [country, setCountry] = useState('')
  const [formIsSubmitting, setFormIsSubmitting] = useState(false)
  const { wallet, publicKey, connected } = useWallet()
  const { checkout, loadCheckout, handleLineItemsToAdd, handleUpdateQuantity } =
    useCheckoutCart()
  const { enqueueInfo } = useInfoBox()
  const { sendTwitterConversionEvent } = useTwitterConversionEvent(
    EVENT_TYPE.PURCHASE
  )
  const searchParams = useSearchParams()

  const handleFormSubmit = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      const cleanUpCheckout = async (checkout: Cart) => {
        try {
          await removeCustomAttributes(checkout.id.toString())
          await loadCheckout(true)
        } catch (error) {
          console.log(error)
          const message = getErrorMessage(
            error,
            "Checkout isn't working properly. Refresh the page and try again."
          )
          enqueueInfo(message, { variant: 'error' })
          captureException(error)
        }
      }

      e.preventDefault()
      setFormIsSubmitting(true)

      try {
        if (!publicKey) {
          throw new Error('Wallet is not connected. Please try again.')
        }

        await cleanUpCheckout(checkout)

        const preorderResponse = await getPreorderCollection()
        if (!preorderResponse.success) {
          throw new Error(
            "We're having trouble getting preorder information. Please try again."
          )
        }

        const products = preorderResponse.data?.products || []
        if (!products) {
          throw new Error(
            "We're having trouble getting preorder information. Please try again."
          )
        }

        const productVariant = products[0].variants[0]
        if (
          checkout.lineItems.length > 0 &&
          checkout.lineItems[0].quantity > 1
        ) {
          handleUpdateQuantity(checkout.lineItems[0].id as string, 1)
        } else if (checkout.lineItems.length === 0) {
          handleLineItemsToAdd(productVariant, 1)
        }

        const isBackpack = connected && wallet?.adapter.name === 'Backpack'

        const customAttributesResponse = await getCustomAttributes(
          publicKey,
          customerRole,
          country,
          isBackpack
        )
        if (!customAttributesResponse.success) {
          throw new DomainError(
            "We're having trouble saving your wallet info. This should be resolved within a few minutes, but you'll need to try to checkout again."
          )
        }

        const checkoutId = checkout.id.toString()
        const addAttributesResponse = await addCustomAttributes(
          checkoutId,
          customAttributesResponse.data
        )

        if (!addAttributesResponse.success) {
          throw new DomainError(
            "We're having trouble saving your wallet info. This should be resolved within a few minutes, but you'll need to try to checkout again."
          )
        }

        const discountCode = searchParams.get('discount')
        if (discountCode) {
          await addDiscount(checkout.id.toString(), discountCode)
        }

        sendTwitterConversionEvent({ conversionId: checkoutId })

        location.assign(checkout.webUrl)
      } catch (e) {
        setFormIsSubmitting(false)
        throw e
      }
    },
    [
      loadCheckout,
      enqueueInfo,
      publicKey,
      checkout,
      customerRole,
      country,
      handleUpdateQuantity,
      handleLineItemsToAdd,
      connected,
      wallet?.adapter.name,
      sendTwitterConversionEvent,
      searchParams,
    ]
  )

  const disabledAgreeAndCheckout = !connected || formIsSubmitting || !country

  return (
    <>
      <HTMLHead noIndex={true} />
      <div
        className={
          'w-full flex min-h-[80vh] items-center content-center mt-7 flex-col h-full justify-around'
        }
      >
        <div className="max-w-[560px] w-full">
          <div>
            <div className="text-cirrus text-[29.4px] leading-[29.11px] md:text-[64px] md:leading-[63.36px] md:tracking-[-0.01em]">
              Your order:
            </div>
            <div className="flex flex-col md:flex-row justify-between mt-[30px] md:mt-[48px] mb-[30px] pb-[23px] border-b-[1px] border-b-[#3A4047]">
              <Image src="/seeker-thumbnail.png" width={92} height={118} alt='seeker thumbnail' className='mb-[30px] md:mb-0' />
              <div className='flex justify-between items-center w-full pl-0 md:pl-[30px]'>
                <div className={'flex flex-col gap-1'}>
                  <div className={'text-[18px] leading-[21.6px] tracking-[-0.02em] font-[600]'}>
                    Preorder: Seeker
                  </div>
                  <div className={'text-nimbus text-[16px] leading-[22.4px] tracking-[-0.02em]'}>
                    Qty 1
                  </div>
                </div>
                <div className={'flex flex-col gap-1 items-end'}>
                  <div className={'text-[18px] leading-[21.6px] tracking-[-0.02em]'}>
                    Early Adopter Window • $500.00
                  </div>
                  <div className={'text-nimbus text-[16px] leading-[22.4px] tracking-[-0.02em]'}>
                    Non-refundable deposit
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="text-cirrus text-[29.4px] leading-[29.11px] md:text-[42px] md:leading-[41.58px]">
            Add your information
          </div>
          <form
            className="flex flex-col w-full relative gap-6"
            onSubmit={handleFormSubmit}
          >
            <FormControl
              sx={{ m: 1, minWidth: 120 }}
              className="flex flex-col gap-1 m-0 mt-[30px] md:mt-[60px]"
            >
              <label className="text-cirrus mb-1 text-[18px] leading-[21.6px] tracking-[-0.02em] md:text-[16px] md:leading-[22.4px]">
                What kind of role are you in?
              </label>

              <Select
                disableUnderline={true}
                id="customerRole"
                name="customerRole"
                value={customerRole}
                onChange={(e) => setCustomerRole(e.target.value)}
                displayEmpty
                variant="standard"
                IconComponent={ExpandMoreIcon}
                className="!text-white bg-[#191e23] !rounded-full h-[48px] !border-none z-10 pl-4 pr-2 custom-select-input !mt-0"
                MenuProps={{
                  className: 'custom-select',
                  sx: {
                    '&& .MuiList-root': {
                      backgroundColor: '#191E23',
                    },
                    '&& .MuiPaper-root': {
                      borderBottomLeftRadius: 20,
                      borderBottomRightRadius: 20,
                    },
                    '&& .Mui-selected': {
                      backgroundColor: '#292f36 !important',
                    },
                    '&& .Mui-selected:hover': {
                      backgroundColor: '#3a4047 !important',
                    },
                    '&& .MuiMenuItem-root': {
                      height: '48px',
                    },
                    '&& .MuiMenuItem-root:hover': {
                      height: '48px',
                      backgroundColor: '#3a4047 !important',
                    },
                  },
                }}
              >
                <MenuItem className="!text-white" value="Developer">
                  Developer
                </MenuItem>
                <MenuItem className="!text-white" value="User">
                  User
                </MenuItem>
                <MenuItem className="!text-white" value="Investor">
                  Investor
                </MenuItem>
                <MenuItem className="!text-white" value="Project founder">
                  Project founder
                </MenuItem>
              </Select>
            </FormControl>
            <FormControl
              sx={{ m: 1, minWidth: 120 }}
              className="flex flex-col gap-1 m-0"
            >
              <label className="text-cirrus mb-1 text-[18px] leading-[21.6px] tracking-[-0.02em] md:text-[16px] md:leading-[22.4px]">
                What country are you in? (required)
              </label>
              <Select
                disableUnderline={true}
                id="country"
                name="country"
                value={country}
                onChange={(e) => {
                  setCountry(e.target.value)
                  console.log(e.target.value)
                }}
                required
                variant="standard"
                IconComponent={ExpandMoreIcon}
                className="!text-white bg-[#191e23] !rounded-full h-[48px] !border-none z-10 pl-4 pr-2 custom-select-input !mt-0"
                MenuProps={{
                  className: 'custom-select',
                  sx: {
                    '&& .MuiList-root': {
                      backgroundColor: '#191E23',
                    },
                    '&& .MuiPaper-root': {
                      borderBottomLeftRadius: 20,
                      borderBottomRightRadius: 20,
                    },
                    '&& .Mui-selected': {
                      backgroundColor: '#292f36 !important',
                    },
                    '&& .Mui-selected:hover': {
                      backgroundColor: '#3a4047 !important',
                    },
                    '&& .MuiMenuItem-root': {
                      height: '48px',
                    },
                    '&& .MuiMenuItem-root:hover': {
                      height: '48px',
                      backgroundColor: '#3a4047 !important',
                    },
                  },
                }}
              >
                {COUNTRIES.map((countryOption, index) => {
                  return (
                    <MenuItem
                      key={`country-option-${index}`}
                      className="!text-white"
                      value={countryOption}
                    >
                      {countryOption}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
            <FormControl
              sx={{ m: 1, minWidth: 120 }}
              className="flex flex-col gap-1 m-0"
            >
              <label className="text-cirrus mb-1 text-[18px] leading-[21.6px] tracking-[-0.02em] md:text-[16px] md:leading-[22.4px]">
                Connect your wallet (required)
              </label>
              <Connect
                inverted
                extraClass='px-[20px] py-[13px] h-[37px] flex items-center justify-center text-[16px] text-[#F6F6F5] leading-[15.84px]'
                extraClassConnected='!bg-transparent text-cirrus border border-[#373C3E] h-[37px] flex items-center justify-center px-6'
              />
            </FormControl>
            <div className="flex flex-row justify-start">
              <WhiteButton
                disabled={disabledAgreeAndCheckout}
                extraClass={`
                  ${
                    disabledAgreeAndCheckout
                      ? '!text-nimbus !bg-[#101618]'
                      : '!text-black !bg-aquamarine'
                  }
                  h-[35px] w-[192px] flex items-center justify-center text-[16px] leading-[15.84px] !px-0
                `}
                type="submit"
              >
                {formIsSubmitting ? (
                  <CircularProgress
                    size={16}
                    className="text-gray-400"
                  />
                ) : 'Agree and checkout'}
              </WhiteButton>
            </div>
            <div className="text-[16px] leading-[19.2px] tracking-[-0.02em] text-nimbus md:leading-[22.4px]">
              By clicking “Agree and checkout” you agree to and acknowledge
              Solana Mobile’s{' '}
              <Link
                href="/privacy-policy-homepage-web"
                {...targetBlank}
                underline="hover"
                className="!text-aquamarine !visited:text-aquamarine"
              >
                Privacy Policy
              </Link>{' '}
              and{' '}
              <Link
                href="/deposit-waitlist-agreement-web"
                {...targetBlank}
                underline="hover"
                className="!text-aquamarine !visited:text-aquamarine"
              >
                Deposit Agreement
              </Link>{' '}
              , including the arbitration clause linked{' '}
              <Link
                href="/deposit-waitlist-agreement-web#disputes"
                {...targetBlank}
                underline="hover"
                className="!text-aquamarine !visited:text-aquamarine"
              >
                here
              </Link>
              , meaning that any dispute related to your deposit and/or the
              product will be resolved through BINDING ARBITRATION on an
              individual, non-class basis. You understand that Solana Mobile (i)
              may share your information with third-parties (ii) is under no
              obligation to provide you a product, and (iii) may choose for any
              reason to provide you a refund.
            </div>
          </form>
        </div>
        <div className="my-8"></div>
      </div>
    </>
  )
}

export const getServerSideProps: GetServerSideProps = withIronSessionSsr(
  async (context) => {
    const { twitterId, referralExists } = context.req.session

    return {
      props: {
        twitterId: twitterId || null,
        referralExists: referralExists || null,
      },
    }
  },
  sessionOptions
)

export default Preorder
